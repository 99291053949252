@import "~react-image-gallery/styles/css/image-gallery.css";

ul {
  list-style-type: none;
  position: absolute;
}

li {
  font-size: 1.7vw;
  font-weight:bold;
  float:left;
}

a:link {
	color: rgb(0, 0, 0);
	text-decoration: none;
}

a:visited {
	color: rgb(0, 0, 0);
	text-decoration: none;
}

li a {
  text-align: center;
  padding: 14px 16px;
  transition: 0.5s;
}

li a:hover {
  text-decoration: underline;
  margin-left:10px ;
  margin-right: 10px;
}

.bigIntro{
  text-align: center;
  font-size: 5vw;
}

.subIntro{
  margin-left: 10vw;
  margin-right: 10vw;
  text-align: center;
  font-size: 2vw;
}

.divider{
  margin-left: 34vw;
  height: 2.7vw;
  
}

.propertyTitle{
  text-align: center;
}

.propertyDesc{
  font-size: 1.3vw;
  text-align: center;
  margin-right: 5vw;
  margin-left: 5vw;
}

.propertyImage{
  padding-left: 2vw;
  padding-right: 2vw;
  height: 25vw;
}

.inlineContainer{
  display: flex;
  align-items: center;
  justify-content: center
}

.footer{
  background-color: black;
  color: aliceblue;
}

.footerText{
  padding-left: 1vw;
}

.hidden{
  display: none;
}

@media (max-width: 1080px) {

  .hidden{
    display: block;
  }

  li {
    font-size: 2vw;
    font-weight:bold;
    float:left;
    padding-left: -10px;
    padding-right: -10px;
  }
  li a:hover {
    text-decoration: underline;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 90%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container {
  padding: 2px 16px;
}

.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 2vw;
  margin: 1vw;
  }

  .button {
    background-color: #04AA6D; /* Green */
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .button1 {
    background-color: white; 
    color: black; 
    border: 2px solid #04AA6D;
  }
  
  .button1:hover {
    background-color: #04AA6D;
    color: white;
  }
  
  .button2 {
    background-color: white; 
    color: black; 
    border: 2px solid #008CBA;
  }
  
  .button2:hover {
    background-color: #008CBA;
    color: white;
  }
  
  .button3 {
    background-color: white; 
    color: black; 
    border: 2px solid #f44336;
  }
  
  .button3:hover {
    background-color: #f44336;
    color: white;
  }
  
  .button4 {
    background-color: white;
    color: black;
    border: 2px solid #e7e7e7;
  }
  
  .button4:hover {background-color: #e7e7e7;}
  
  .button5 {
    background-color: white;
    color: black;
    border: 2px solid #555555;
  }
  
  .button5:hover {
    background-color: #555555;
    color: white;
  }

.propertyPage{
  padding-left: 4vw;
}

.fein {
  display: grid;
  align-items: center; 
  grid-template-columns: 1fr 1fr 0fr;
  column-gap: 5vw;
 }

 .album {
  padding-right: 3vw;
 }